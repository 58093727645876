import axios from 'axios';

// https://8124-37-204-10-198.ngrok-free.app
const url = 'https://hotpal.ru:5000';

export const autocompleteSearch = (term) => {

  return axios.get(url + "/autocomplete-search", {

    params: {
      query: term
    }
  }).then(res => {

    // return res;
    return res.data.filter(el => el.type === 'hotels');
  });
}

export const hotelsDataSingle = (hotels) => {

  return axios.get(url + "/hotels-data", {

    params: {
      arrayHotels: hotels.map(el => el.id)
    }
  }).then(res => {

    return res.data;

  });
}

export const hotelsData = (hotels) => {
  return axios.get(url + "/hotels-data", {
    params: {
      arrayHotels: hotels.map(el => el.id)
    }
  }).then(res => {

    return res.data;

  });
}


export const geoSearch = (longitude, latitude, dateFrom, dateTo, personCount) => {

  return axios.get(url + "/geo-search", {
    params: {
      longitude: longitude,
      latitude: latitude,
      dateFrom: dateFrom,
      dateTo: dateTo,
      personCount: personCount,
    }
  }).then(res => {

    return res.data;
  });

}


export const hotelPage = (id, dateFrom, dateTo, personCount) => {

  return axios.get(url + "/hotel-page", {
    params: {
      id: id,
      dateFrom: dateFrom,
      dateTo: dateTo,
      personCount: personCount,
    }
  }).then(res => {
    return res.data;
  });


}
