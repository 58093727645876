
import Form from 'components/forms/hotelsSearch/Form';


import { hotelsData } from 'pages/hotels/hooks/searchHotels';

import { autocompleteSearch } from 'pages/hotels/hooks/searchHotels';

import { getMaxListing } from 'components/getMaxListing';

import { useEffect, useState } from 'react';

import moment from "moment";

import Tabs from 'blocks/Tabs';

import { connect } from 'react-redux';

// import { addSearch } from 'servicesMysql/changeSearch';
import { timestampCustomDay, timestampCustomDayTime } from 'services/timestampCustom';

import { changeActions } from 'servicesMysql/changeActions';

const HotelSearchPanelMap = ({
  account,
  formData,
  loading,
  setLoading,
  listingsCoords,
  setSearchDate,
  setHotels,
  setLoadingHotels,
  bestHotels,
  bestHotelsLoad
}) => {


  const submitSuccess = (e) => {

    setLoading(true);


    let valuesData = formData.values;


    let currentDate = valuesData.dateRange.split(' - ');

    let dateFrom = currentDate[0].split(".").reverse().join("-");
    let dateTo = currentDate[1].split(".").reverse().join("-");

    setSearchDate([dateFrom, dateTo]);


    if (firstLoad === false) {
      // addSearch({ uid: account.uid, dateSearch: timestampCustomDayTime(), type: 'hotels-search', dateRange: formData.values?.dateRange, hotelFind: formData.values?.hotelFind });

      changeActions({
        ...account,
        'uid': account.uid,
        'date': timestampCustomDay(),
        'action': 'searchHotels'
      });

    }

    autocompleteSearch(valuesData.hotelFind).then(res => {

      hotelsData(res).then(response => {
        const updatedResponse = response.map((el) => {
          const findCount = bestHotels.find((e) => e.idHotel === el.id);

          return {
            ...el, // Исходные данные из response
            countTravels: findCount ? findCount.count : 0, // Если нашли — берем count, иначе 0
          };
        });

        const sortedHotels = updatedResponse.sort((a, b) => b.countTravels - a.countTravels);


        setHotels(sortedHotels);
        setLoadingHotels(false);
      });


    });





  }

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {

    if (formData && firstLoad && bestHotelsLoad) {


      setFirstLoad(false)
      submitSuccess();
    }

  }, [formData, firstLoad, bestHotelsLoad])



  return (
    <>
      <Tabs active="hotels" />
      <Form
        submitSuccess={submitSuccess}
        initialValues={{
          city: 2395,
          personCount: 2,
          dateRange: moment().format('DD.MM.YYYY') + ' - ' + moment().add(2, 'days').format('DD.MM.YYYY')
        }}
        loading={loading}
        listingsCoords={listingsCoords}
      // resetAll={resetAll}
      />
    </>
  )
}

const mapStateToProps = (state) => {

  return {
    account: state.account,
    formData: state.form.hotelsSearch,
    bestHotels: state.globalState.bestHotels,
    bestHotelsLoad: state.globalState.bestHotelsLoad,
  }
}

export default connect(mapStateToProps)(HotelSearchPanelMap);

